import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

// components
import CampioneGratuitoForm from "../../components/campione-gratuito-form";
import Hero from "../../utils/hero";
import Page from "../../utils/page";
import theme from "../../utils/theme";

// markup
const FreeSamplePage = () => {
  return (
    <Page
      lang="en"
      title="Free Sample of Disposable Wet Wipes and Bibs"
      description="Try our disposable wet wipes and non-woven bibs. Your kit includes: 8 wipes (1 for each fragrance), 4 bibs (1 for each format)."
      type="ContactPage"
      image="/richiedi-campione-gratuito.jpg"
    >
      <Hero
        title="Free Sample"
        description={
          <>
            Try our <strong>disposable wet wipes</strong> and{" "}
            <strong>non-woven bibs</strong>.
          </>
        }
        background={theme.palette.primary.main}
        backgroundImage={
          <StaticImage
            style={{ height: "100%" }}
            quality={100}
            src="../../images/backgrounds/fragranza-menta.jpg"
            alt="Mint fragrance"
            layout="fullWidth"
          />
        }
      />

      <CampioneGratuitoForm language="en" />
    </Page>
  );
};

export default FreeSamplePage;
